<template>
    <section class="repair padding_10_15">
        <div class="top-bar bg-white">
            <el-form label-width="10px" size="small">
                <div class="flex flex-warp">
                    <el-form-item>
                        <!-- 张晓瑜新增 -->
                        <el-select v-model="formSearch.keyWordCode" placeholder="请选择报修单查询"
                                   style="width: 200px;">
                            <el-option label="报修编号" :value="1"/>
                            <el-option label="报修人" :value="2"/>
                            <el-option label="联系方式" :value="3"/>
                            <el-option label="房间" :value="4"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="formSearch.keyWord" placeholder="请输入内容" style="width: 200px;"/>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="repairTypes" placeholder="请选择维修位置" @change="onChanges"
                                   style="width: 200px;">
                            <el-option v-for="item in repairLocationsList" :key="item.uuid" :label="item.name"
                                       :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="formSearch.repairType" placeholder="请选择维修类型" :loading="onLoading"
                                   style="width: 200px;">
                            <el-option v-for="item in housingEtypeList" :key="item.uuid" :label="item.name"
                                       :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="repairDate" type="daterange" value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd" range-separator="至" start-placeholder="报修开始日期"
                                        end-placeholder="报修结束日期"/>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="formSearch.status" placeholder="请选择跟进状态" style="width: 200px;">
                            <el-option v-for="item in housingEstateList" :key="item.value" :label="item.label"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>
                    <!--                    2023-10-12 16:53:39 王江毅修改，没有字段无法发布特此注释 2、报修单查询条件增加“区域”、“备注”的模糊搜索-->
                    <el-form-item>
                        <el-select v-model="formSearch.district" placeholder="请选择区域" style="width: 200px;">
                            <el-option v-for="(item,index) in districtList" :key="index" :label="item" :value="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="formSearch.auditComment" placeholder="请输入备注" style="width: 200px;"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                        <el-button @click="handleReset" size="small">重置</el-button>
                        <el-button type="primary" @click="handleExport">导出数据</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <!--            <el-input v-model="formSearch.repairSn" size="small" placeholder="请输入维修编号"
                                  style="margin: 0 9px; width: 250px"/>
                        <el-input v-model="formSearch.repairPeopleName" size="small" placeholder="请输入报修人"
                                  style="margin: 0 9px; width: 250px"/>
                        <el-select v-model="formSearch.repairType" placeholder="请选择类型" size="small" style="margin:0px 10px">
                            <el-option v-for="item in housingEtypeList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
                        </el-select>-->
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getRepairTableData" :columns="tableColumn"
                   :query="formSearch" :height="750" :row-style="rowStyle">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row, row:{ stateCode } }">
                    <div class="table-tools">
                        <span class="table-btn" @click="ckHandle(row)">查看</span>
                        <span class="table-btn" @click="shenHe(row)"
                              v-if="stateCode === 0 || stateCode === 1 || stateCode === 2">审核</span>
                        <span class="table-btn" @click="weixiu(row)" v-if="stateCode === 3">维修完成</span>
                        <span class="table-btn" @click="wancheng(row)" v-if="stateCode === 5">确认完成</span>
                        <span class="table-btn" @click="dialogRefundclick(row)"  v-if="stateCode === 3">退单</span>
                        <!--<span class="table-btn" v-if="row.stateCode === 0" @click="handle(row)">处理</span>-->
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="维修处理" :visible.sync="dialogVisible" show-footer top="5vh" width="700px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="维修时间" prop="commentDate">
                        <el-date-picker v-model="formPublish.commentDate" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"/>
                    </el-form-item>
                </div>
<!--              陈守亮-2024-4-7-新增照片多个上传替换新组件  -->
              <div class="flex">
                <el-form-item label="维修前照片" prop="beforeImgs" class="samplePhotos">
                  <upload-pictureCard :uuidList="formPublish.beforeImgs" :limit="9" @on-success="handleSuccess"
                                      @on-remove="handleRemove"/>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item label="维修后照片" prop="afterImgs" class="samplePhotos">
                  <upload-pictureCard :uuidList="formPublish.afterImgs" :limit="9" @on-success="afterImgsSuccess"
                                      @on-remove="afterImgsRemove"/>
                </el-form-item>
              </div>
<!--                <div class="flex">-->
<!--                    <el-form-item label="维修前照片" prop="beforeImgs">-->
<!--                        <upload-avatar  @on-success="handleUploadBeforeImgsSuccess" :uuid="formPublish.beforeImgs"/>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="维修后照片" prop="afterImgs">-->
<!--                        <upload-avatar @on-success="handleUploadAfterImgsSuccess" :uuid="formPublish.afterImgs"/>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="flex justify-between"
                     style="border-left: #2A8AF7 5px solid;font-weight: bold;font-size: 16px;padding-left: 10px">
                    <div>更换配件</div>
                    <el-link type="primary" :underline="false" style="margin-right: 30px"
                             icon="el-icon-circle-plus-outline" @click="addMachine">添加配件
                    </el-link>
                </div>
                <div style="height: 300px;overflow-y: auto">
                    <div v-for="(item,index) in formPublish.repairMountings" :key="index">
                        <div style="padding-left: 20px">
                            配件（{{ index + 1 }}）
                        </div>
                        <div class="flex">
                            <el-form-item label="旧型号">
                                <el-input v-model="formPublish.repairMountings[index].oldMountingsName"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="旧品牌">
                                <el-input v-model="formPublish.repairMountings[index].oldMountingsBrand"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <el-form-item label="新型号">
                                <el-input v-model="formPublish.repairMountings[index].mountingsName"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                            <el-form-item label="新品牌">
                                <el-input v-model="formPublish.repairMountings[index].mountingsBrand"
                                          placeholder="请输入内容"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="查看" :visible.sync="ckDialogVisible" top="5vh" width="1300px">
            <el-steps :active="active" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                <el-step title="提交">
                    <div slot="description">
                        <div>申请人: {{ data.repairPeopleName }}</div>
                        <div>申请时间: {{ timeFormat(data.createdTime, "yyyy-MM-dd HH:mm:ss") }}</div>
                    </div>
                </el-step>
                <el-step title="审核">
                    <div slot="description">
                        <div>审核人: {{ data.auditor }}</div>
                        <div v-if="active>1">审核时间: {{ timeFormat(data.auditTime, "yyyy-MM-dd HH:mm:ss") }}</div>
                    </div>
                </el-step>
                <el-step title="维修">
                    <div slot="description">
                        <div v-if="active>=2">维修人: {{ data.maintainMan }}</div>
                        <div v-if="active>2">维修时间: {{ timeFormat(data.commentdate, "yyyy-MM-dd HH:mm:ss") }}</div>
                    </div>
                </el-step>
                <el-step title="确认">
                    <div slot="description">
                        <div v-if="active>=3">确认人: {{ data.confirmor }}</div>
                        <div v-if="active>3">确认时间: {{ timeFormat(data.confirmTime, "yyyy-MM-dd HH:mm:ss") }}</div>
                    </div>
                </el-step>
                <el-step title="查看"></el-step>
            </el-steps>
            <div class="flex justify-between">
                <el-card shadow="never" style="width: 300px">
                    <div slot="header">
                        <span>报修信息</span>
                    </div>
                    <!-- 2023/09/06张晓瑜修改报修信息 -->
                    <div style="display: flex; justify-content: space-between; align-items: flex-end"
                         v-for="(item, index) in list" :key="index">
                        <div style="margin-bottom: 10px">报修时间:
                            {{ timeFormat(item.createdTime, "yyyy-MM-dd HH:mm:ss") }}
                        </div>
                        <el-button @click="repairDialogVisiblel(item)" type="text">详情</el-button>
                        <r-e-dialog title="报修信息" :visible.sync="RepairDialogVisiblelopen" width="30%"
                                    :before-close="handleRepairClosel">
                            <div style="margin-bottom: 10px">
                                报修时间:
                                {{ timeFormat(infoItem.createdTime, "yyyy-MM-dd HH:mm:ss") }}
                            </div>
                            <div style="margin-bottom: 10px">预约时间: {{ timeFormat(infoItem.repairdate, "yyyy-MM-dd") }}
                            </div>
                            <div style="margin-bottom: 10px">房间: {{ infoItem.apartmentName }}</div>
                            <div style="margin-bottom: 10px">联系人: {{ infoItem.repairPeopleName }}</div>
                            <div style="margin-bottom: 10px">联系方式: {{ infoItem.repairPeoplePhone }}</div>
                            <div style="margin-bottom: 10px">维修项目: {{ infoItem.repairTypeName }}</div>
                            <div style="margin-bottom: 10px">维修备注: {{ infoItem.repairContent }}</div>
                            <div style="margin-bottom: 10px">报修登记图片:</div>
                            <div class="flex" style="height: 300px;overflow:scroll; flex-wrap: wrap;">
                                <!-- <div v-for="(item,index) in data.thumbnail" :key="index"> -->
                                <div class="flex justify-center" style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px" v-for="item in infoItem.thumbnail" :key="item">
                                    <el-image :src="item" class="flex align-center" :preview-src-list="infoItem.thumbnail"
                                              >
                                        <div slot="error" style="font-size: 30px;">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                        <div slot="placeholder">加载中...</div>
                                    </el-image>
                                </div>
                                <!-- </div> -->
                            </div>
                        </r-e-dialog>
                    </div>
                </el-card>
                <el-card shadow="never" style="width: 500px">
                    <div slot="header">
                        <span>维修信息</span>
                    </div>
                    <div style="display: flex; justify-content: space-between; align-items: flex-end"
                         v-for="(item, index) in list" :key="index">
                        <div style="margin-bottom: 10px">维修时间:
                            {{ timeFormat(item.commentdate, "yyyy-MM-dd HH:mm:ss") }}
                        </div>
                        <el-button @click="maintenanceDialogVisibleler(item)" type="text">详情</el-button>
                        <r-e-dialog title="维修信息" :visible.sync="maintenanceDialogVisiblelopen" width="30%"
                                    :before-close="handlemaintenanceClosel">
                            <div style="margin-bottom: 10px">维修人: {{ infoItem.maintainMan }}</div>
                            <div style="margin-bottom: 10px">维修时间:
                                {{ timeFormat(infoItem.commentdate, "yyyy-MM-dd HH:mm:ss") }}
                            </div>
                               <!--2024-4-7-陈守亮-富航照片回显重制-->
                          <div style="margin-bottom: 10px">维修前图片:</div>
                          <div class="flex" style="height: 300px;overflow:scroll; flex-wrap: wrap;">
                            <!-- <div v-for="(item,index) in data.thumbnail" :key="index"> -->
                            <div class="flex justify-center" style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px" v-for="item in infoItem.maintainBefore" :key="item">
                              <el-image :src="item" class="flex align-center"
                                        :preview-src-list="infoItem.maintainBefore">
                                <div slot="error" style="font-size: 30px;">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                                <div slot="placeholder">加载中...</div>
                              </el-image>
                            </div>
                            <!-- </div> -->
                          </div>

                          <div style="margin-bottom: 10px">维修后图片:</div>
                          <div class="flex" style="height: 300px;overflow:scroll; flex-wrap: wrap;">
                            <!-- <div v-for="(item,index) in data.thumbnail" :key="index"> -->
                            <div class="flex justify-center" style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px" v-for="item in infoItem.maintainAfter" :key="item">
                              <el-image :src="item" class="flex align-center"
                                        :preview-src-list="infoItem.maintainAfter">
                                <div slot="error" style="font-size: 30px;">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                                <div slot="placeholder">加载中...</div>
                              </el-image>
                            </div>
                            <!-- </div> -->
                          </div>

<!--                            <div style="margin-bottom: 10px">维修前图片:</div>-->
<!--                            <div class="flex justify-center"-->
<!--                                 style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">-->
<!--                                <el-image :src="createFullImageUrl(item)" class="flex align-center"  v-for="item in infoItem.maintainBefore" :key="item"-->
<!--                                          :preview-src-list="[createFullImageUrl(item)]">-->
<!--                                    <div slot="error" style="font-size: 30px;">-->
<!--                                        <i class="el-icon-picture-outline"></i>-->
<!--                                    </div>-->
<!--                                    <div slot="placeholder">加载中...</div>-->
<!--                                </el-image>-->
<!--                            </div>-->
<!--                            <div style="margin-bottom: 10px">维修后图片:</div>-->
<!--                            <div class="flex justify-center"-->
<!--                                 style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">-->
<!--                                <el-image :src="createFullImageUrl(item)" class="flex align-center"  v-for="item in infoItem.maintainAfter" :key="item"-->
<!--                                          :preview-src-list="[createFullImageUrl(item)]">-->
<!--                                    <div slot="error" style="font-size: 30px;">-->
<!--                                        <i class="el-icon-picture-outline"></i>-->
<!--                                    </div>-->
<!--                                    <div slot="placeholder">加载中...</div>-->
<!--                                </el-image>-->
<!--                            </div>-->

                            <div v-if="infoItem.repairMountings && infoItem.repairMountings.length > 0">
                                <div style="font-weight: 900; color: #303133; text-align: center;">
                                    <h2>更换配件</h2>
                                </div>
                                <div>
                                    <div v-for="(item,index) in infoItem.repairMountings" :key="index">
                                        <div>配件（{{ index + 1 }}）</div>
                                        <div style="padding-left: 10px;margin-bottom: 5px">旧配件名称:
                                            {{ item.oldMountingsName }}
                                        </div>
                                        <div style="padding-left: 10px;margin-bottom: 5px">旧配件品牌:
                                            {{ item.oldMountingsBrand }}
                                        </div>
                                        <div style="padding-left: 10px;margin-bottom: 5px">新配件名称:
                                            {{ item.mountingsName }}
                                        </div>
                                        <div style="padding-left: 10px;margin-bottom: 5px">新配件品牌:
                                            {{ item.mountingsBrand }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </r-e-dialog>

                    </div>
                    <div>

                    </div>
                </el-card>
                <!-- <el-card shadow="never" style="width: 300px" v-if="data.repairMountings && data.repairMountings.length > 0">
                    <div slot="header">
                        <span>更换配件</span>
                    </div>
                    <div style="height: 460px;;overflow-y: auto">
                        <div v-for="(item,index) in data.repairMountings" :key="index">
                            <div>配件（{{index+1}}）</div>
                            <div style="padding-left: 10px;margin-bottom: 5px">旧配件名称: {{item.oldMountingsName}}</div>
                            <div style="padding-left: 10px;margin-bottom: 5px">旧配件品牌: {{item.oldMountingsBrand}}</div>
                            <div style="padding-left: 10px;margin-bottom: 5px">新配件名称: {{item.mountingsName}}</div>
                            <div style="padding-left: 10px;margin-bottom: 5px">新配件品牌: {{item.mountingsBrand}}</div>
                        </div>
                    </div>
                </el-card> -->
                <el-card shadow="never" style="width: 300px">
                    <div slot="header">
                        <span>维修确认</span>
                    </div>
                    <div v-for="(item, index) in list" :key="index">
                        <div style="margin-bottom: 10px;width: 245px;">确认人: {{ item.confirmor }}</div>
                        <div style="margin-bottom: 10px">确认时间:
                            {{ timeFormat(item.confirmTime, "yyyy-MM-dd HH:mm:ss") }}
                        </div>
                    </div>
                </el-card>
            </div>
            <!--            2023-10-12 16:54:45 王江毅修改 没有接口无法发布特此注释 ②查看弹窗回显“备注”，并增加编辑按钮，可编辑备注内容-->
            <div style="height: 25px;"></div>
            <div style="border-top: 1px solid rgba(187, 187, 187, 1);margin:0 -25px;padding: 25px">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="40px">
                    <div class="flex align-end">
                        <el-form-item label="备注" prop="desc">
                            <el-input type="textarea" v-model="ruleForm.desc" style="width: 250px;" :rows="3"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="bianji">保存</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </r-e-dialog>

        <r-e-dialog title="审核" :visible.sync="shenHeShow" show-footer top="5vh" width="600px"
                    @click-submit="shenHeSubmit" @click-cancel="shenHeCancel" @close="shenHeCancel">
            <el-form ref="examine" label-width="110px" size="small" :model="examineData" :rules="examineRules">
                <div class="flex">
                    <el-form-item label="维修备注">
                        <el-input type="textarea" autosize placeholder="" v-model="examineData.repairContent" disabled
                                  style="width: 350px;"/>
                    </el-form-item>
                </div>
                <!--                <div class="flex">
                                    <el-form-item label="审核状态" prop="isAuditApprove">
                                        <el-radio-group v-model="examineData.isAuditApprove">
                                            <el-radio-button :label="false">维修处理</el-radio-button>
                                            <el-radio-button :label="true">管家处理</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="flex" v-if="examineData.isAuditApprove">
                                    <el-form-item label="审核备注" prop="comment">
                                        <el-input type="textarea" :rows="3" v-model="examineData.comment" style="width: 350px;"
                                                  placeholder="直接处理需要填写审核备注"/>
                                    </el-form-item>
                                </div>-->

                <!--                2023-10-12 16:56:15 王江毅修改 接口未修改无法发布 特此注释 4、增加备注输入框：
                                流程：审核>维修处理，弹窗增加备注输入框，内容回显至列表“备注”处（即与审核>管家处理备注内容回显至同一位置）-->
                <div class="flex">
                    <el-form-item label="审核" prop="isAuditApprove">
                        <el-radio-group v-model="examineData.isAuditApprove">
                            <el-radio-button :label="false">通过</el-radio-button>
                            <el-radio-button :label="true">不通过</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="备注" :prop="examineData.isAuditApprove ? 'comment' : ''"
                                  :key="examineData.isAuditApprove">
                        <el-input type="textarea" :rows="3" v-model="examineData.comment" style="width: 350px;"
                                  placeholder="直接处理需要填写审核备注"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
      <dialogRefund ref="dialogRefundRef" @clickCancel="handleResetrefund"></dialogRefund>
    </section>
</template>

<script>
import {tableRepairColumn} from "@/views/home/data";
import {
    repairAudit, repairMaintain, repairAuditMaintain, getRepairList, repairMaintainRead, repairInfo,
    getHousingETypeListApi, getRepairLocationsListApi, auditCommmentUpdate, repairExportApi
} from "@/api/repair";
import uploadAvatar from "@/components/Upload/upload-avatar";
import {MessageSuccess, MessageError} from "@custom/message";
import {createFullImageUrl} from "@/components/Upload";
import {timeFormat} from "@custom/index";
import {getAddressList} from "@/api/community-management";
import {downloadByData} from "@/utils/hooks/download";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";

export default {
    name: "repair",
    components: {
      uploadPictureCard,
        // uploadAvatar,
         dialogRefund: () => import("./part/dialog-refund")
    },
    data() {
        return {
            ckHandlevalue:null,
            beforeImgslist:[],
            afterImgslist:[],
            pNumber:'',
            tableColumn: tableRepairColumn,
            tableData: [],
            repairTypes: null,
            formSearch: {
                page:'4',
                repairSn: null, // 维修编号
                repairPeopleName: null,// 维修人
                repairType: null, // 投诉类型
                status: null, // 跟进状态
                startDate: null, // 开始报修时间
                endDate: null, // 结束报修时间
                keyWordCode: null, // 保修单查询
                keyWord: null, // 模糊查询值
                district: null,
                auditComment: null
            },
            repairDate: [], // 报修时间
            housingEtypeList: [],// 投诉类型列表
            housingEstateList: [
                // {value: 1, label: '待维修'},
                {value: 2, label: '待审核'},
                {value: 3, label: '审核通过'},
                {value: 4, label: '审核不通过'},
                {value: 5, label: '维修完成'},
                {value: 6, label: '确认维修完成'},
                {value: 7, label: '已完结'},
                {value: 8, label: '已退单'}
            ],// 跟进状态列表
            dialogVisible: false,
            formPublish: {
                uuid: null,
                commentDate: null,
                beforeImgs: null,
                afterImgs: null,
                repairMountings: []
            },
            rules: {
                commentDate: [{required: true, message: '请选择维修时间', trigger: 'change'}],
                beforeImgs: [{required: true, message: '请上传维修前图片', trigger: 'blur'}],
                afterImgs: [{required: true, message: '请上传维修后图片', trigger: 'blur'}],
            },
            examineRules: {
                isAuditApprove: [{required: true, message: '请选择审核状态', trigger: 'change'}],
                comment: [{required: true, message: '请填写备注', trigger: 'blur'}],
            },
            thumbnailFinish: [],
            ckDialogVisible: false,
            data: {
                repairContent: null,
                thumbnail: null,
            },
            ckUuidList: [],
            examineData: {
                uuid: null,
                repairContent: null,
                isAuditApprove: null,
                comment: null,
            },
            shenHeShow: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            active: 1,
            RepairDialogVisiblelopen: false, //  报修信息弹窗2
            maintenanceDialogVisiblelopen: false,// 维修信息弹窗
            infoItem: {},
            list: {},
            repairLocationsList: [], //维修位置列表
            onLoading: false, // 加载状态
            //配件对象
            accessoryObject: {
                oldMountingsName: null,
                oldMountingsBrand: null,
                mountingsName: null,
                mountingsBrand: null,
            },

            ruleForm: {
                desc: null,
            },

            districtList: []
        };
    },
    async created() {
        // 维修位置列表
        await this.getRepairLocationsList();
        let res = await getAddressList();
        this.districtList = res.list;
    },
    methods: {
      // 陈守亮-2024-4-7-富航照片陈守亮更新组件
      handleSuccess(response) {
        const uuid = response.file.response.returnObject.info.uuid;
        console.log(uuid,response)
        const hasElement = this.beforeImgslist.includes(uuid);
        if (hasElement) {
          console.log("数组中包含元素3");
        } else {
          console.log("数组中不包含元素3");
          this.beforeImgslist.push(uuid)
        }
        console.log(this.formPublish.beforeImgs)
      },
      handleRemove({fileList}) {
        console.log('删除',fileList)
        let list = []
        fileList.forEach(item => {
          list.push(item.response.returnObject.info.uuid)
        })
        this.beforeImgslist = list
        console.log(this.beforeImgslist)
      },

      // 陈守亮-2024-4-7-富航照片陈守亮更新组件  维修后照片
      afterImgsSuccess(response) {
        const uuid = response.file.response.returnObject.info.uuid;
        console.log(uuid,response)
        const hasElement = this.afterImgslist.includes(uuid);
        if (hasElement) {
          console.log("数组中包含元素3");
        } else {
          console.log("数组中不包含元素3");
          this.afterImgslist.push(uuid)
        }
        console.log(this.formPublish.beforeImgs)
      },
      afterImgsRemove({fileList}) {
        console.log('删除',fileList)
        let list = []
        fileList.forEach(item => {
          list.push(item.response.returnObject.info.uuid)
        })
        this.afterImgslist = list
        console.log(this.afterImgslist)
      },
        //维修列表加载
        getRepairTableData(params) {
            return getRepairList(params);
        },
        // 搜索
        handleSearch(isSearch = true) {
          console.log(this.repairTypes)
          // console.log(this.$refs['orderTableRef'].pNumber)
          // console.log(this.pNumber)
          this.$refs['orderTableRef'].pNumbe = this.pNumber
          console.log(this.$refs['orderTableRef'].pNumber)
          // repairTypes
          //2024-4-2-陈守亮-修改维修编辑之后保持当前页面刷新
            this.$refs['orderTableRef'].getTableData(isSearch);

          if(!isSearch) this.ckHandle(this.ckHandlevalue)
        },
        // 重置
        handleReset() {
            this.formSearch = {};
            this.repairDate = [];
            this.repairTypes = ''
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
      handleResetrefund(){
        Promise.resolve(this.formSearch).then(() => this.handleSearch());

      },
        /*handle(data) {
            let {uuid} = data;
            this.formPublish.uuid = uuid;
            this.dialogVisible = true;
        },*/


        //查看维修
        ckHandle(data) {
          // ruleForm.desc
          this.ckHandlevalue = data
          console.log(data)
            let that = this;
            let {uuid, stateCode, repairType} = data;
            // alert(stateCode)
            stateCode = Number(stateCode);
            let loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            if (stateCode === 3) repairMaintainRead({uuid});

            repairInfo({uuid}).then(res => {
              console.log(res)
              console.log('maintainAfter',res.list.maintainAfter);
                let {list} = res;
                this.list = list
                let {
                    thumbnail, auditor, maintainMan, confirmor, repairPeopleName, createdTime, auditTime, commentdate,
                    confirmTime
                } = list[0]
                thumbnail = thumbnail ? thumbnail.split(";").filter(item => item !== "") : [];
                console.log('thumbnailss',thumbnail);
                let userName = localStorage.getItem("userName");

                //根据维修状态回显执行步骤

                if (stateCode === 0 || stateCode === 1) {
                    auditor = userName;
                    that.active = 1;
                }

                if (stateCode === 3) {
                    maintainMan = userName;
                    that.active = 2;
                }

                if (stateCode === 4) {
                    // maintainMan = userName;
                    that.active = 4;
                }

                if (stateCode === 5) {
                    confirmor = userName;
                    that.active = 3;
                }

                if (stateCode === 6) {
                    that.active = 4;
                }

                if (stateCode === 7 || stateCode === 8 ) {
                    that.active = 4;
                }

                that.data = {
                    ...list, repairType, thumbnail, auditor, maintainMan, repairPeopleName, createdTime, auditTime,
                    commentdate, confirmor, confirmTime, uuid
                }

                that.ruleForm.desc = data.auditComment;
                that.ckDialogVisible = true;
            }).finally(() => loading.close());
        },

        //回显图片url
        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },

        //打开审核弹窗
        shenHe(data) {
            this.examineData = {...data};
            this.shenHeShow = true;
        },
        //审核确定
        shenHeSubmit() {
            const that = this;

            // 表单提交
            that.$refs["examine"].validate((valid) => {
                if (valid) {
                    const {uuid, isAuditApprove, comment} = that.examineData;
                    if (isAuditApprove) {
                        if (!comment) {
                            MessageError("请填写备注");
                            return;
                        }
                    }
                    let loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});

                    repairAudit({uuid, isAuditApprove, comment}).then(res => {
                        MessageSuccess('审核成功');
                        that.shenHeCancel();
                    }).finally(() => loading.close());
                }
            });
        },

        //审核取消
        shenHeCancel() {
            this.shenHeShow = false;
            this.examineData = {uuid: null, repairContent: null, isAuditApprove: null, comment: null,};
            this.handleSearch();
        },

        //确认完成
        wancheng(data) {
            let {uuid} = data;
            let that = this;
            this.$confirm('此操作将完成维修，请先确认是否真的完成维修?', '注意', {type: 'warning'}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                repairAuditMaintain({uuid}).then(res => {
                    MessageSuccess('确认成功');
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },

        //日期格式化
        timeFormat(date, fmt) {
            return date ? timeFormat(new Date(date), fmt) : "";
        },

        //维修完成
        weixiu(data) {
            const oldData = this.formPublish;
            const accessoryObject = this.accessoryObject;
            this.formPublish = {...oldData, ...data};
            this.formPublish.repairMountings = [{...accessoryObject}];
            this.dialogVisible = true;
        },
        //维修处理确认
        clickSubmit() {
        let that = this;
          that.formPublish.beforeImgs = that.beforeImgslist.join()
          that.formPublish.afterImgs = that.afterImgslist.join()

          console.log(that.formPublish)
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {uuid, commentDate, beforeImgs, afterImgs, repairMountings} = that.formPublish;
                    console.log('repairMountings',repairMountings);
                    commentDate = (new Date(commentDate)).getTime();
                    const data = {uuid, commentDate, beforeImgs, afterImgs, repairMountings}
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    repairMaintain(data).then(res => {
                        MessageSuccess('维修成功');
                        that.clickCancel();
                        that.beforeImgslist = [];
                        that.afterImgslist = [];
                        that.formPublish.beforeImgs = [];
                        that.formPublish.afterImgs = [];
                    }).finally(() => loading.close());
                }
            });
        },

        //维修处理关闭
        clickCancel() {
            const accessoryObject = this.accessoryObject;
            this.$refs["formPublish"].resetFields();
            this.formPublish = {
                uuid: null, commentDate: null, beforeImgs: null, afterImgs: null, repairMountings: [],
            }
            this.formPublish.repairMountings = [{...accessoryObject}];
            this.handleSearch();
            this.dialogVisible = false;
        },

        //维修前照片上传
        handleUploadBeforeImgsSuccess({info}) {
          console.log(info.uuid)
         // 判断数组中是否包含元素3
          const hasElement = this.beforeImgslist.includes(info.uuid);
          if (hasElement) {
            console.log("数组中包含元素3");
          } else {
            console.log("数组中不包含元素3");
            this.beforeImgslist.push(info.uuid)

          }
            // 上传图片
            this.formPublish.beforeImgs = info.uuid;
        },

        //维修后照片上传
        handleUploadAfterImgsSuccess({info}) {
            // 上传图片
            this.formPublish.afterImgs = info.uuid;
        },

        //添加配件
        addMachine() {
            const accessoryObject = this.accessoryObject;
            this.formPublish.repairMountings.push({...accessoryObject});
        },

        //查看维修记录 报修信息 详情
        repairDialogVisiblel(item) {
          console.log('详情',item)
          try {
            item.thumbnail = item.thumbnail ? item.thumbnail.split(',') : null;
            item.thumbnail = item.thumbnail.map(createFullImageUrl)
            console.log('item.thumbnail',item.thumbnail);
          }catch(err) {
            console.log(err)}
            this.infoItem = item;
            this.RepairDialogVisiblelopen = true;
        },

        // 关闭报修信息弹窗
        handleRepairClosel() {
            this.RepairDialogVisiblelopen = false;
        },

        //查看维修记录 维修信息 详情 维修后图片:
        maintenanceDialogVisibleler(item) {
          console.log('item',item)
          try {
            item.maintainAfter = item.maintainAfter ? item.maintainAfter.split(',') : null;
            item.maintainBefore = item.maintainBefore ? item.maintainBefore.split(',') : null;
            item.maintainAfter = item.maintainAfter.map(createFullImageUrl)
            item.maintainBefore = item.maintainBefore.map(createFullImageUrl)
          }catch(err) {
            console.log(err)}
          console.log(item)
          this.infoItem = item;
            this.maintenanceDialogVisiblelopen = true;
        },

        // 关闭维修信息弹窗
        handlemaintenanceClosel() {
            this.maintenanceDialogVisiblelopen = false;
        },

        // 维修位置列表
        async getRepairLocationsList() {
            const {list} = await getRepairLocationsListApi()
            this.repairLocationsList = list
        },

        // 投诉类型列表
        async getHousingETypeList(uuid) {
            const {list} = await getHousingETypeListApi({positionUuid: uuid})
            this.onLoading = false
            this.housingEtypeList = list
        },

        //报修位置切换事件
        onChanges(value) {
            this.onLoading = true
            //  投诉类型列表
            this.getHousingETypeList(value)
        },

        rowStyle({row, index}) {
            if (row['commentdate']) {
                return {background: ""}
            } else {
                const createTime = (new Date(timeFormat(new Date(row['createTime'])))).getTime();
                const today = (new Date(timeFormat(new Date()))).getTime();
                const day = Math.trunc(Math.abs(createTime - today) / 24 / 60 / 60 / 1000);
                if (day > 5 && day <= 8) {
                    return {background: '#FCF9D4'}
                } else if (day > 7) {
                    return {background: '#F6D5CD'}
                }
            }
        },

        //编辑按钮事件
        bianji() {
            const that = this;
            const {uuid} = that.data;
            const comment = that.ruleForm.desc;
            let loadingOptions = that.loadingOptions;
            that.pNumber = that.$refs['orderTableRef'].pNumber;
            console.log(that.pNumber);
            const loading = that.$loading({...loadingOptions});    
            
            auditCommmentUpdate({uuid, comment}).then(res => {
                that.ckHandlevalue.auditComment = that.ruleForm.desc;
                MessageSuccess('备注成功');
                // 关闭弹窗
                that.ckDialogVisible = false;
                // 2024/04/17 张晓瑜修改局部刷新，重新加载组件
                that.$refs['orderTableRef'].refresh(); // 假设这里是你组件的引用，调用其刷新方法
            }).finally(() => loading.close());
},
        handleExport() {
            const that = this;
            const pageSize = this.$refs["orderTableRef"].pSize;
            const page = this.$refs["orderTableRef"].pNumber;
            const formSearch = this.formSearch;
            const paramData = {page, pageSize, ...formSearch};
            const loadingOptions = that.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            repairExportApi(paramData).then((res) => {
                downloadByData({data: res, filename: `维修工单${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.xlsx`,});
            }).finally(() => loading.close());
        },
      // 退单
      dialogRefundclick(row) {
        this.$refs["dialogRefundRef"].openDialog(row)
      },
    },
    watch: {
        // 报修时间
        repairDate(value) {
            if (value && value.length === 2) {
                let [startDate, endDate] = value;
                this.formSearch.startDate = startDate;
                this.formSearch.endDate = endDate;
            } else {
                this.formSearch.startDate = null;
                this.formSearch.endDate = null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.repair {
    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        padding-bottom: 0;
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            /*white-space: nowrap;*/
            text-align: center;
        }
    }
}
</style>
